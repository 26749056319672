import React from "react";

import { IconContext } from "react-icons";
import styled from "styled-components";

import { clsx } from "utils/clsx";

const Container = styled.span`
  .ant-btn & {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn & svg {
    color: currentColor !important;
  }
`;

type IAntDIconProps = {
  icon: React.ElementType;
  className?: string;
  size?: string | number;
  color?: string;
};

const AntDIcon = React.forwardRef<HTMLSpanElement, IAntDIconProps>(
  ({ icon: Icon, className, size, color, ...props }, ref) => {
    const { size: contextSize } = React.useContext(IconContext);
    return (
      <Container ref={ref} className={clsx("anticon", className)} role="img" {...props}>
        <Icon height={size || contextSize} width={size || contextSize} size={size || contextSize} color={color} />
      </Container>
    );
  },
);

export default AntDIcon;
