import React from "react";

import { Layout, Menu } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { AiOutlineCloudUpload, AiOutlineLogout, AiOutlineQuestionCircle } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useMilvueUser } from "contexts/userContext";
import { Role } from "types/globalTypes";

import AntDIcon from "./UI/AntdIcon";

const Container = styled(Layout)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  height: 100vh;
`;

const NavMenu = styled(Menu)`
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const { logout } = useAuth0();
  const location = useLocation();
  const { user } = useMilvueUser();

  return (
    <Container>
      <Layout.Sider collapsedWidth={48} collapsed>
        <NavMenu mode="inline" selectedKeys={[location.pathname]}>
          <Menu.Item key="/worklist" icon={<AntDIcon icon={BsCardList} />}>
            <Link to={`/worklist${location.search}`}>worklist</Link>
          </Menu.Item>
          {user.role === Role.ADMIN && (
            <Menu.Item key="/users" icon={<AntDIcon icon={FiUsers} />}>
              <Link to="/users">users</Link>
            </Menu.Item>
          )}
          <Menu.Item key="/upload" icon={<AntDIcon icon={AiOutlineCloudUpload} />}>
            <Link to="/upload">upload</Link>
          </Menu.Item>
          <Menu.Item key="/about" icon={<AntDIcon icon={AiOutlineQuestionCircle} />}>
            <Link to="/about">about</Link>
          </Menu.Item>
          <Menu.Item
            key="logout"
            onClick={() =>
              logout({
                clientId: "mqYtoY491ajDP1IiWa0EtWiwu5ceygRo",
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })
            }
            icon={<AntDIcon icon={AiOutlineLogout} />}
          >
            logout
          </Menu.Item>
        </NavMenu>
      </Layout.Sider>
      <Layout.Content>{children}</Layout.Content>
    </Container>
  );
};

export default AppLayout;
