import React from "react";

import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";

type AntdProviderProps = {
  children: React.ReactNode;
};

const AntdProvider = ({ children }: AntdProviderProps) => {
  return (
    <ConfigProvider locale={enUS} componentSize="small">
      {children}
    </ConfigProvider>
  );
};

export default AntdProvider;
