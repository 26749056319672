/**
 * Concatenates an indefinite number of strings
 * (classNames) into one
 * @returns {string}
 */
export const clsx = (...rest: any[]): string =>
  [...rest]
    // only keep strings
    .filter(s => typeof s === "string")
    .join(" ")
    .trim();
