import { Spin } from "antd";
import styled from "styled-components";

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingFallback = () => (
  <LoadingContainer>
    <Spin size="large" />
  </LoadingContainer>
);

export default LoadingFallback;
