import { gql } from "@apollo/client";

export const fullUser = gql`
  fragment fullUser on User {
    id
    createdAt
    updatedAt
    deleted
    email
    role
  }
`;
