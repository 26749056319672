import { withAuthenticationRequired } from "@auth0/auth0-react";

import { MilvueUserProvider } from "contexts/userContext";

import Routes from "./Routes";
import { AntdProvider, ApolloProvider } from "config";

const App = () => {
  return (
    <ApolloProvider>
      <MilvueUserProvider>
        <AntdProvider>
          <Routes />
        </AntdProvider>
      </MilvueUserProvider>
    </ApolloProvider>
  );
};

export default withAuthenticationRequired(App);
