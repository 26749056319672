import { red, green, yellow, grey, blue } from "@ant-design/colors";
import { DefaultTheme, keyframes } from "styled-components";

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const milvueTheme: DefaultTheme = {
  colors: {
    primary: {
      main: "#45DABE",
      light: "#60f3d8",
      dark: "#31b199",
    },
    secondary: {
      main: "#24303E",
      light: "#2D3C4D",
      lighter: "#334355",
      dark: "#1C232C",
    },
    semantic: {
      error: red[4],
      success: green[3],
      warning: yellow[4],
      neutral: grey[5],
      info: blue[4],
    },
    grey,
    text: {
      main: "rgba(255, 255, 255, 0.85)",
      hint: "rgba(255, 255, 255, 0.45)",
    },
  },
  animations: {
    rotate,
  },
};
