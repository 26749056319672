import { PathoName, SortColumn, SortOrder } from "types/globalTypes";

export interface ISearchOptions {
  startDate?: string | null; // YYYY-MM-DD
  endDate?: string | null; // YYYY-MM-DD
  sortBy: SortColumn;
  sortOrder: SortOrder;
  page: number;
  incidence: string | null;
  tag: PathoName | null;
  forAnnotation: boolean;
  flags: string[];
}

interface IData {
  lastViewed: string | null;
}

export type ISystemState = ISearchOptions & IData;

const dateTimeToDateFormat = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

// this later will be configurable through
// the configuration page (or modal)
const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

export const initialState: ISystemState = {
  startDate: dateTimeToDateFormat(yesterday),
  endDate: dateTimeToDateFormat(today),
  sortBy: SortColumn.DATE,
  sortOrder: SortOrder.DESC,
  lastViewed: null,
  page: 0,
  incidence: null,
  tag: null,
  forAnnotation: true,
  flags: [],
};
