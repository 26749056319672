import loadable from "@loadable/component";
import { Redirect, Route, Switch } from "react-router-dom";

import AppLayout from "components/Layout";
import LoadingFallback from "components/Shared/LoadingFallback";
import { useMilvueUser } from "contexts/userContext";
import { Role } from "types/globalTypes";

const routes = [
  {
    key: "AboutPage",
    component: loadable(() => import(/* webpackChunkName: "AboutPage" */ "./pages/AboutPage")),
    path: "/about",
    exact: true,
  },
  {
    key: "UserListPage",
    component: loadable(() => import(/* webpackChunkName: "UserListPage" */ "./pages/UserListPage")),
    path: "/users",
    exact: true,
    allowed: [Role.ADMIN],
  },
  {
    key: "WorklistPage",
    component: loadable(() => import(/* webpackChunkName: "WorklistPage" */ "./pages/WorklistPage")),
    path: ["/worklist", "/worklist/:query"],
    exact: true,
  },
  {
    key: "ViewerPage",
    component: loadable(
      () =>
        import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "ViewerPage"  */
          "./pages/ViewerPage"
        ),
    ),
    path: "/viewer/:studyId",
    exact: true,
  },
  {
    key: "SmartXpertPage",
    component: loadable(
      () =>
        import(
          /* webpackPrefetch: true */
          /* webpackChunkName: "ViewerPage"  */
          "./pages/SmartXpertPage"
        ),
    ),
    path: "/smartxpert/:studyInstanceId",
    exact: true,
  },
  {
    key: "UploadPage",
    component: loadable(() => import(/* webpackChunkName: "UploadPage" */ "./pages/UploadPage")),
    path: "/upload",
    exact: true,
  },
];

// TODO: move auth context access closer to where its used - should probably be kept high level
// will need to investigate these 'component={}' for future version of react router
// See this : https://twitter.com/ryanflorence/status/1206645805366431744
const Routes = () => {
  const { user } = useMilvueUser();

  return (
    <AppLayout>
      <Switch>
        {routes
          .filter(r => (r.allowed ? r.allowed.indexOf(user.role) !== -1 : true))
          .map(({ component: Component, path, exact, key }) => (
            <Route key={key} path={path} exact={exact}>
              <Component fallback={<LoadingFallback />} />
            </Route>
          ))}
        {user.role === Role.ADMIN ? <Redirect to="/users" /> : <Redirect to="/worklist" />}
      </Switch>
    </AppLayout>
  );
};

export default Routes;
