import { shallowEqual, useSelector } from "react-redux";

import type { ISystemState } from "./initialState";

export const useMilvueSelector = <TSelected>(selector: (state: ISystemState) => TSelected) =>
  useSelector<ISystemState, TSelected>(selector, shallowEqual);

export type { MilvueAction } from "./actions";
export type { ISearchOptions } from "./initialState";
export { rootReducer } from "./reducers";
