import { MilvueAction } from "./actions";
import { initialState, ISystemState } from "./initialState";

// eslint-disable-next-line
export const rootReducer = (state: ISystemState | undefined = initialState, action: MilvueAction): ISystemState => {
  switch (action.type) {
    case "UPDATE_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    case "UPDATE_SORT_BY":
      return {
        ...state,
        sortBy: action.payload,
      };
    case "UPDATE_SORT_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };
    case "UPDATE_START_DATE":
      return {
        ...state,
        startDate: action.payload,
      };
    case "UPDATE_END_DATE":
      return {
        ...state,
        endDate: action.payload,
      };
    case "UPDATE_LAST_VIEWED":
      return {
        ...state,
        lastViewed: action.payload,
      };
    case "UPDATE_SELECT_INCIDENCE":
      return {
        ...state,
        incidence: action.payload,
      };
    case "UPDATE_SELECT_TAG":
      return {
        ...state,
        tag: action.payload,
      };
    case "UPDATE_FOR_ANNOTATION":
      return {
        ...state,
        forAnnotation: action.payload,
      };
    case "UPDATE_FLAGS":
      return {
        ...state,
        flags: action.payload,
      };
    default:
      return state;
  }
};
