import React from "react";

import { ApolloClient, InMemoryCache, ApolloProvider, defaultDataIdFromObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "@apollo/client/link/http";
import { useAuth0 } from "@auth0/auth0-react";

import "isomorphic-fetch";

const fetcher = (...args: [RequestInfo, RequestInit | undefined]) => {
  return fetch(...args);
};

type ProviderProps = {
  children: React.ReactNode;
};

export default function Provider({ children }: ProviderProps) {
  const { getAccessTokenSilently } = useAuth0();

  const apiUrl = new URL("graphql", process.env.REACT_APP_API_URL || window.origin.replace("app", "graphql"));
  const httpLink = createHttpLink({
    uri: apiUrl.toString(),
    fetch: fetcher,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        console.log(`[GraphQL error]: Message: ${message}`);
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    assumeImmutableResults: true,
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject: object => defaultDataIdFromObject(object),
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
