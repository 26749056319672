import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { useCheckSessionQuery } from "actions/users/queries";
import { checkSession_checkSession } from "types/checkSession";

interface MilvueUserContextProps {
  user: checkSession_checkSession;
  isDemo: boolean;
}

const initialState: Omit<MilvueUserContextProps, "user"> = {
  isDemo: true,
};

const milvueUserContext = createContext<MilvueUserContextProps | undefined>(undefined);

const DATA_POLLING_INTERVAL = 1000 * 60 * 2;

type MilvueUserProviderProps = {
  children: React.ReactNode;
};

const MilvueUserProvider = ({ children }: MilvueUserProviderProps) => {
  const { data, error } = useCheckSessionQuery();
  const milvueUser = data && data.checkSession;
  const [loading, setLoading] = useState(true);
  const [setupState, setSetupState] = useState(initialState);
  const { logout } = useAuth0();

  useEffect(() => {
    if (error) {
      logout({
        clientId: "mqYtoY491ajDP1IiWa0EtWiwu5ceygRo",
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }, [error]);

  useEffect(() => {
    const setup = async (user: checkSession_checkSession) => {
      const isDemo = user.group.name.startsWith("demo-");
      setSetupState({ isDemo });
      setLoading(false);
    };
    // Run once immediately
    if (milvueUser) {
      setup(milvueUser);
    }
    const interval = setInterval(() => {
      if (milvueUser) {
        setup(milvueUser);
      }
    }, DATA_POLLING_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [milvueUser]);

  // TODO: add spinner - loading message
  if (loading) return null;

  return (
    <milvueUserContext.Provider value={{ user: milvueUser as checkSession_checkSession, ...setupState }}>
      {children}
    </milvueUserContext.Provider>
  );
};

const useMilvueUser = () => {
  const context = useContext(milvueUserContext);
  if (context === undefined) {
    throw new Error("useMilvueUser must be used within a MilvueUserProvider");
  }
  return context;
};

export { MilvueUserProvider, useMilvueUser };
