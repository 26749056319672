import React from "react";

import { useHistory } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

type ProviderProps = {
  children: React.ReactNode;
};

export default function Provider({ children }: ProviderProps) {
  const history = useHistory();

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain="milvue.eu.auth0.com"
      clientId="mqYtoY491ajDP1IiWa0EtWiwu5ceygRo"
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://milvueapi",
      }}
    >
      {children}
    </Auth0Provider>
  );
}
