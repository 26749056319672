/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Patient gender - obtained from dicom
 */
export enum Gender {
  F = "F",
  M = "M",
}

/**
 * Analysed pathologies + MATERIAL.
 * Used for Label and Box
 */
export enum PathoName {
  ANKLE_EFFUSION = "ANKLE_EFFUSION",
  BONE_LESION_CONDENSED = "BONE_LESION_CONDENSED",
  BONE_LESION_LYTIC = "BONE_LESION_LYTIC",
  BONE_LESION_PERIOSTAL_LINES = "BONE_LESION_PERIOSTAL_LINES",
  CATHETER = "CATHETER",
  DISLOCATION = "DISLOCATION",
  ELBOW_FAT_PAD = "ELBOW_FAT_PAD",
  FRACTURE = "FRACTURE",
  KNEE_ARTHROSIS = "KNEE_ARTHROSIS",
  KNEE_EFFUSION = "KNEE_EFFUSION",
  KNEE_HEMARTHROSIS = "KNEE_HEMARTHROSIS",
  MATERIAL = "MATERIAL",
  NODULE_PARENCHYMA = "NODULE_PARENCHYMA",
  NONE = "NONE",
  PARENCHYMA_OPACITY = "PARENCHYMA_OPACITY",
  PLEURAL_EFFUSION = "PLEURAL_EFFUSION",
  PNEUMOTHORAX = "PNEUMOTHORAX",
  VERTEBRAL_COMPACTION = "VERTEBRAL_COMPACTION",
  OEDEMA = "OEDEMA",
}

/**
 * Prediction status from predictor
 */
export enum PredictionStatus {
  ABNORMAL = "ABNORMAL",
  DOUBT = "DOUBT",
  ERROR = "ERROR",
  NORMAL = "NORMAL",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  OUT_SCOPE = "OUT_SCOPE",
  RUNNING = "RUNNING",
}

/**
 * Role options for users
 * TODO: change SUPER_ADMIN to ANNOTATOR
 */
export enum Role {
  ADMIN = "ADMIN",
  ALGO = "ALGO",
  ANNOTATOR = "ANNOTATOR",
  RADIOLOGIST = "RADIOLOGIST",
  SUPER_ADMIN = "SUPER_ADMIN",
  UNKNOWN = "UNKNOWN",
  URGENTIST = "URGENTIST",
}

/**
 * Sorting fields for QueryList.
 */
export enum SortColumn {
  DATE = "DATE",
  IS_FLAGGED = "IS_FLAGGED",
  SCORE = "SCORE",
}

/**
 * Sorting order for QueryList sorting fields.
 */
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Global label values
 * TODO: use PredictionStatus
 */
export enum TagValue {
  DOUBT = "DOUBT",
  NO = "NO",
  YES = "YES",
}

/**
 * Box input from app
 */
export interface BoxInput {
  x: number;
  y: number;
  height: number;
  width: number;
  value: PathoName;
}

/**
 * Incidence input
 */
export interface IncidenceInput {
  value: string;
}

/**
 * Query inputs for study search
 */
export interface QueryInput {
  startDate?: any | null;
  endDate?: any | null;
  sortBy: SortColumn;
  sortOrder: SortOrder;
  tags?: PathoName[] | null;
  incidences?: string[] | null;
  flags?: string[] | null;
  forAnnotation?: boolean | null;
  studyInstanceUIDs?: string[] | null;
}

/**
 * Global label creation from app - source inferred from user
 */
export interface TagInput {
  name: PathoName;
  value: TagValue;
}

/**
 * User input for creation
 * Group of the user inferred from creator
 * TODO: add group input for ADMIN users creation
 */
export interface UserCreationInput {
  email: string;
  role: Role;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
