import { gql, useQuery } from "@apollo/client";

import { checkSession } from "types/checkSession";
import { users } from "types/users";

import { fullUser } from "./fragments";

export const usersQuery = gql`
  query users {
    users {
      ...fullUser
    }
  }
  ${fullUser}
`;

export const useUsersQuery = () => {
  return useQuery<users>(usersQuery, {
    fetchPolicy: "network-only",
    query: usersQuery,
  });
};

const checkSessionQuery = gql`
  query checkSession {
    checkSession {
      id
      role
      group {
        id
        name
        nameServerURL
        dockerToken
      }
      email
    }
  }
`;

export const useCheckSessionQuery = () =>
  useQuery<checkSession>(checkSessionQuery, {
    query: checkSessionQuery,
  });
