import React from "react";

import { IconContext } from "react-icons";
import { Router } from "react-router";
import { ThemeProvider } from "styled-components";

import { milvueTheme } from "styles/theme";
import { milvueHistory } from "utils/milvueHistory";

import "./index.less";
import { Auth0Provider, ReduxProvider } from "config";

type RootProviderProps = {
  children: React.ReactNode;
};

const RootProvider = ({ children }: RootProviderProps) => {
  return (
    <Router history={milvueHistory}>
      <Auth0Provider>
        <ReduxProvider>
          <ThemeProvider theme={milvueTheme}>
            <IconContext.Provider value={{ color: "#F4F4F4", size: "1em" }}>{children}</IconContext.Provider>
          </ThemeProvider>
        </ReduxProvider>
      </Auth0Provider>
    </Router>
  );
};

export default RootProvider;
