import React from "react";

import ReactDOM from "react-dom/client";
import RootProvider from "RootProvider";

import App from "./App";
import "./index.less";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <RootProvider>
      <App />
    </RootProvider>
  </React.StrictMode>,
);
